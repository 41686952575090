import {
  type ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { type SubmitHandler, useFieldArray, useForm } from 'react-hook-form';

import {
  BaseCollapsibleMenu,
  FormBase,
  type IFormModalProps,
  useToast,
} from '@gbs-monorepo-packages/common';
import { zodResolver } from '@hookform/resolvers/zod';

import { ColorPickerSelectData } from '../../../../components/ColorPickerSelectData';
import {
  fontUnits as fontUnitsData,
  fonts as fontsGrapes,
} from '../../../../constants/Fonts';
import {
  type IAdditionalColors,
  type IUpdateGlobalStyle,
  globalStyleDefault,
  maxHueRotate,
  maxValueFontSize,
  maxValuePageWidth,
  maxValuePercentagePageWidth,
  minHueRotate,
  minValueFontSize,
  minValuePageWidth,
} from '../../../../constants/GlobalStyles';
import {
  type GlobalStyleSchema,
  type ITemplateColorsUpdateSchema,
  globalStyleSchema,
} from '../../../../formSchemas/globalStyleSchema';
import { useCourse } from '../../../../hooks/useCourse';
import {
  type IFontDTO,
  type ITemplateColors,
} from '../../../../services/courses';
import { ColorPickerInput } from '../ColorPickerInput';
import {
  ColorPickerContainer,
  ColorPreview,
  Container,
  FormModalCustom,
  InputContentCustom,
  Loading,
  LoadingContainer,
  OverlayDiv,
  OverlayText,
  SelectDataCustom,
  SliderCustom,
  SmallInputTextCustom,
  SmallSelectDataCustom,
  TemplateImagesColorsSection,
} from './styles';

export interface IFont extends IFontDTO {
  index: number;
  origin?: string;
  selected?: boolean;
}

interface IGlobalStyleModalProps
  extends Partial<
    Omit<IFormModalProps, 'children' | 'onAccept' | 'onOpenChange'>
  > {
  courseId: number;
  loading: boolean;
  onAccept: (data: IUpdateGlobalStyle) => Promise<void>;
  onDecline: () => void;
  open: boolean;
  waitToOpen?: boolean;
  needSave?: boolean;
}

const generateOldNewColors = (colors: IAdditionalColors) => {
  const colorsArray = Object.values(colors);
  return colorsArray.map((color) => ({ color }));
};

const generateOldNewTemplateColors = (
  templateColors: ITemplateColors
): ITemplateColorsUpdateSchema => ({
  ...templateColors,
});

export const generateOldNewAdditionalColors = (
  additionalColors: IAdditionalColors
): Array<{ color?: string | undefined } | undefined> => {
  return generateOldNewColors(additionalColors);
};

const generateTemplateColorsToUpdate = (
  templateColors: ITemplateColorsUpdateSchema
): ITemplateColors => {
  return {
    ...globalStyleDefault.templateColors,
    ...templateColors,
  };
};

export const generateAdditionalColorsToUpdate = (
  oldAdditionalColors: IAdditionalColors,
  additionalColors: Array<{ color?: string | undefined } | undefined>
): IAdditionalColors => {
  const newAdditionalColors: Record<string, string> = {};
  const oldObjAdditionalColors = Object.values(oldAdditionalColors ?? {});
  oldObjAdditionalColors.forEach((oldColorObj, idx) => {
    const newColor = additionalColors[idx]?.color;
    if (newColor) {
      newAdditionalColors[oldColorObj] = newColor;
    }
  });
  return newAdditionalColors;
};

const NumberKeyRegex = /[0-9]/;

export const GlobalStyleModal = ({
  courseId,
  loading,
  onAccept,
  onDecline,
  open,
  waitToOpen = false,
  needSave = false,
  ...props
}: IGlobalStyleModalProps): JSX.Element | null => {
  const isSelectOpen = useRef(new Set());
  const { selectedCourse } = useCourse();

  const globalStyleSchemaModal = useForm<GlobalStyleSchema>({
    resolver: zodResolver(globalStyleSchema),
    mode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      buttonColor:
        selectedCourse?.globalStyle?.buttonColor ??
        globalStyleDefault.buttonColor,
      buttonFontColor:
        selectedCourse?.globalStyle?.buttonFontColor ??
        globalStyleDefault.buttonFontColor,
      headlineFont: selectedCourse?.globalStyle?.headlineFont?.family ?? '',
      headlineFontColor:
        selectedCourse?.globalStyle?.headlineFontColor ??
        globalStyleDefault.headlineFontColor,
      headlineFontSize:
        selectedCourse?.globalStyle?.headlineFontSize ??
        globalStyleDefault.headlineFontSize,
      headlineFontSizeUnit:
        selectedCourse?.globalStyle?.headlineFontSizeUnit ??
        globalStyleDefault.headlineFontSizeUnit,
      hueRotate:
        selectedCourse?.globalStyle?.hueRotate ?? globalStyleDefault.hueRotate,
      pageWidth:
        selectedCourse?.globalStyle?.pageWidth ?? globalStyleDefault.pageWidth,
      pageWidthUnit:
        selectedCourse?.globalStyle?.pageWidthUnit ??
        globalStyleDefault.pageWidthUnit,
      paragraphFont: selectedCourse?.globalStyle?.paragraphFont?.family ?? '',
      paragraphFontColor:
        selectedCourse?.globalStyle?.paragraphFontColor ??
        globalStyleDefault.paragraphFontColor,
      paragraphFontSize:
        selectedCourse?.globalStyle?.paragraphFontSize ??
        globalStyleDefault.paragraphFontSize,
      paragraphFontSizeUnit:
        selectedCourse?.globalStyle?.paragraphFontSizeUnit ??
        globalStyleDefault.paragraphFontSizeUnit,
      primaryColor:
        selectedCourse?.globalStyle?.primaryColor ??
        globalStyleDefault.primaryColor,
      secondaryColor:
        selectedCourse?.globalStyle?.secondaryColor ??
        globalStyleDefault.secondaryColor,
      templateColors: generateOldNewTemplateColors(
        selectedCourse?.globalStyle?.templateColors ??
          globalStyleDefault.templateColors
      ),
      additionalColors: generateOldNewAdditionalColors(
        selectedCourse?.globalStyle?.additionalColors?.at(0) ??
          globalStyleDefault.additionalColors?.at(0) ??
          {}
      ),
    },
  });

  const {
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    watch,
    reset,
    trigger,
    control,
  } = globalStyleSchemaModal;

  const values = watch();
  console.log(values);
  console.log('selectedCourse', selectedCourse);
  console.log('globalStyleDefault', globalStyleDefault);

  const { addToast } = useToast();
  const [loadingFonts, setLoadingFonts] = useState(true);
  const [fonts, setFonts] = useState<IFont[]>([]);
  const loadedFontsOnce = useRef(false);

  const resetForm = useCallback(() => {
    const globalStyle = selectedCourse?.globalStyle ?? globalStyleDefault;
    const defaultFont = fonts.at(0) ?? globalStyleDefault.paragraphFont;

    reset({
      buttonColor: globalStyle?.buttonColor ?? globalStyleDefault.buttonColor,
      buttonFontColor:
        globalStyle?.buttonFontColor ?? globalStyleDefault.buttonFontColor,
      headlineFont:
        globalStyle?.headlineFont?.family ?? defaultFont?.family ?? '',
      headlineFontColor:
        globalStyle?.headlineFontColor ?? globalStyleDefault.headlineFontColor,
      headlineFontSize:
        globalStyle?.headlineFontSize ?? globalStyleDefault.headlineFontSize,
      headlineFontSizeUnit:
        globalStyle?.headlineFontSizeUnit ??
        globalStyleDefault.headlineFontSizeUnit,
      hueRotate: globalStyle?.hueRotate ?? globalStyleDefault.hueRotate,
      pageWidth: globalStyle?.pageWidth ?? globalStyleDefault.pageWidth,
      pageWidthUnit:
        globalStyle?.pageWidthUnit ?? globalStyleDefault.pageWidthUnit,
      paragraphFont:
        globalStyle?.paragraphFont?.family ?? defaultFont?.family ?? '',
      paragraphFontColor:
        globalStyle?.paragraphFontColor ??
        globalStyleDefault.paragraphFontColor,
      paragraphFontSize:
        globalStyle?.paragraphFontSize ?? globalStyleDefault.paragraphFontSize,
      paragraphFontSizeUnit:
        globalStyle?.paragraphFontSizeUnit ??
        globalStyleDefault.paragraphFontSizeUnit,
      primaryColor:
        globalStyle?.primaryColor ?? globalStyleDefault.primaryColor,
      secondaryColor:
        globalStyle?.secondaryColor ?? globalStyleDefault.secondaryColor,
      additionalColors: generateOldNewAdditionalColors(
        globalStyle?.additionalColors?.at(0) ??
          globalStyleDefault.additionalColors?.at(0) ??
          {}
      ),
    });
  }, [fonts, reset, selectedCourse?.globalStyle]);

  const onSubmit: SubmitHandler<GlobalStyleSchema> = (
    data: GlobalStyleSchema
  ) => {
    const globalStyle = selectedCourse?.globalStyle ?? globalStyleDefault;
    const defaultFont = fonts.at(0) ?? globalStyleDefault.paragraphFont;

    let headlineFont = globalStyle.headlineFont ?? defaultFont;
    let paragraphFont = globalStyle.paragraphFont ?? defaultFont;

    for (const font of fonts) {
      if (font.family === data.headlineFont) {
        headlineFont = font;
      }
      if (font.family === data.paragraphFont) {
        paragraphFont = font;
      }
    }

    void onAccept?.({
      buttonColor: data.buttonColor,
      buttonFontColor: data.buttonFontColor,
      headlineFont,
      headlineFontColor: data.headlineFontColor,
      headlineFontSize: data.headlineFontSize,
      headlineFontSizeUnit: data.headlineFontSizeUnit,
      hueRotate: data.hueRotate,
      pageWidth: data.pageWidth,
      pageWidthUnit: data.pageWidthUnit,
      paragraphFont,
      paragraphFontColor: data.paragraphFontColor,
      paragraphFontSize: data.paragraphFontSize,
      paragraphFontSizeUnit: data.paragraphFontSizeUnit,
      primaryColor: data.primaryColor,
      secondaryColor: data.secondaryColor,
      templateColors: generateTemplateColorsToUpdate(data.templateColors),
      additionalColors: generateAdditionalColorsToUpdate(
        globalStyle.additionalColors?.at(0) ??
          globalStyleDefault.additionalColors?.at(0) ??
          {},
        data.additionalColors
      ),
    });
  };

  const additionalColorsBottomRef = useRef<HTMLDivElement>(null);

  const [hueRotateBaseColor, setHueRotateBaseColor] = useState(
    globalStyleDefault.secondaryColor
  );

  const hueRotateSlider = useMemo(
    () => [values.hueRotate ?? 0],
    [values.hueRotate]
  );

  const hasAnyTemplateColor = useMemo(
    () =>
      [
        values.templateColors.primaryColor,
        values.templateColors.secondaryColor,
        values.templateColors.backgroundColor,
        values.templateColors.accentColor,
      ].some((value) => value),
    [values.templateColors]
  );
  const { fields } = useFieldArray({
    control,
    keyName: 'key',
    name: 'additionalColors',
  });

  const handleDeclineSaveGlobalStyle = useCallback(() => {
    if (!isSelectOpen.current.size) {
      loadedFontsOnce.current = false;
      onDecline?.();
      resetForm();
    }
  }, [onDecline, resetForm]);

  const handleHeadlineFontSelectChange = useCallback(
    (value: string) => {
      setValue('headlineFont', value);
    },
    [setValue]
  );

  const handleParagraphFontSelectChange = useCallback(
    (value: string) => {
      setValue('paragraphFont', value);
    },
    [setValue]
  );

  const handlePageWidthChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { valueAsNumber } = target;

    switch (true) {
      case values.pageWidthUnit === '%' &&
        valueAsNumber > maxValuePercentagePageWidth:
        setValue('pageWidth', maxValuePercentagePageWidth);
        break;
      case valueAsNumber < minValuePageWidth:
        setValue('pageWidth', minValuePageWidth);
        break;
      case valueAsNumber > maxValuePageWidth:
        setValue('pageWidth', maxValuePageWidth);
        break;
    }
  };

  const handleHeadlineFontSizeChange = ({
    target: { valueAsNumber },
  }: ChangeEvent<HTMLInputElement>) => {
    switch (true) {
      case valueAsNumber < minValueFontSize:
        setValue('headlineFontSize', minValueFontSize);
        break;
      case valueAsNumber > maxValueFontSize:
        setValue('headlineFontSize', maxValueFontSize);
        break;
    }
  };

  const handleParagraphFontSizeChange = useCallback(
    ({ target: { valueAsNumber } }: ChangeEvent<HTMLInputElement>) => {
      switch (true) {
        case valueAsNumber < minValueFontSize:
          setValue('paragraphFontSize', minValueFontSize);
          break;
        case valueAsNumber > maxValueFontSize:
          setValue('paragraphFontSize', maxValueFontSize);
          break;
      }
    },
    [setValue]
  );

  const handlePageWidthUnitChange = useCallback(
    (value: string) => {
      setValue('pageWidth', minValuePageWidth);
      setValue('pageWidthUnit', value);
    },
    [setValue]
  );

  const handleHeadlineFontUnitChange = useCallback(
    (value: string) => {
      setValue('headlineFontSizeUnit', value);
    },
    [setValue]
  );

  const handleParagraphFontUnitChange = useCallback(
    (value: string) => {
      setValue('paragraphFontSizeUnit', value);
    },
    [setValue]
  );

  const handleHeadlineFontColorChange = useCallback(
    (color: string) => {
      setValue('headlineFontColor', color);
      void trigger('headlineFontColor');
    },
    [setValue, trigger]
  );

  const handleParagraphFontColorChange = useCallback(
    (color: string) => {
      setValue('paragraphFontColor', color);
      void trigger('paragraphFontColor');
    },
    [setValue, trigger]
  );

  const handleButtonBackgroundColorChange = useCallback(
    (color: string) => {
      setValue('buttonColor', color);
      void trigger('buttonColor');
    },
    [setValue, trigger]
  );

  const handleButtonFontColorChange = useCallback(
    (color: string) => {
      setValue('buttonFontColor', color);
      void trigger('buttonFontColor');
    },
    [setValue, trigger]
  );

  const handlePrimaryColorChange = useCallback(
    (color: string) => {
      setValue('primaryColor', color);
      void trigger('primaryColor');
    },
    [setValue, trigger]
  );

  const handleSecondaryColorChange = useCallback(
    (color: string) => {
      setValue('secondaryColor', color);
      void trigger('secondaryColor');
    },
    [setValue, trigger]
  );

  const handleHueRotateBaseColorChange = useCallback(
    (color: string) => {
      setHueRotateBaseColor(color);
    },
    [setValue, trigger]
  );

  const handleHueRotateChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const { valueAsNumber } = target;

      switch (true) {
        case valueAsNumber < minHueRotate:
          setValue('hueRotate', minHueRotate);
          break;
        case valueAsNumber > maxHueRotate:
          setValue('hueRotate', maxHueRotate);
          break;
      }
    },
    [setValue]
  );
  const handleHueRotateSliderChange = useCallback(
    (value: number[]) => {
      const newValue = value.at(0);
      setValue('hueRotate', newValue ?? globalStyleDefault.hueRotate);
    },
    [setValue]
  );

  const handleTemplateColorsChange = useCallback(
    (color: string, colorKey?: `additionalColors.${number}.color`) => {
      if (colorKey) {
        setValue(colorKey, color.toUpperCase());
        void trigger(colorKey);
      }
    },
    [setValue, trigger]
  );

  useEffect(() => {
    loadedFontsOnce.current = false;
  }, [courseId]);

  useEffect(() => {
    const loadFontsOnce = () => {
      loadedFontsOnce.current = true;
      setLoadingFonts(true);

      const resultWithIndex: IFont[] =
        selectedCourse?.fonts.map((data, index) => ({
          ...data,
          index,
          origin: 'course',
        })) ?? [];
      const grapesFontsWithIndex: IFont[] = fontsGrapes.map((data, index) => ({
        ...data,
        index,
      }));

      let currentIndex = 0;
      const allFontsWithIndex = [
        ...grapesFontsWithIndex.map((obj) => ({
          ...obj,
          index: currentIndex++,
        })),
        ...resultWithIndex.map((obj) => ({
          ...obj,
          index: currentIndex++,
        })),
      ];
      setFonts(allFontsWithIndex);

      setLoadingFonts(false);

      return allFontsWithIndex satisfies IFont[];
    };

    let defaultFont = fonts.at(0);

    if (open && courseId && !loadedFontsOnce.current) {
      const newFonts = loadFontsOnce();
      defaultFont = newFonts.at(0) ?? defaultFont;
    }

    if (!values.headlineFont) {
      setValue('headlineFont', defaultFont?.family ?? '');
    }

    if (!values.paragraphFont) {
      setValue('paragraphFont', defaultFont?.family ?? '');
    }
  }, [
    addToast,
    courseId,
    fonts,
    open,
    handleParagraphFontSelectChange,
    selectedCourse,
    setValue,
  ]);

  const handleOpenChange = useCallback((isOpen: boolean, key: string) => {
    if (isOpen) {
      isSelectOpen.current.add(key);
    } else {
      isSelectOpen.current.delete(key);
    }
  }, []);

  const fontsData = useMemo(
    () => fonts.map((font) => ({ key: font.family, value: font.family })),
    [fonts]
  );

  return !open ? null : (
    <FormBase.Provider {...globalStyleSchemaModal}>
      <FormModalCustom
        acceptText="Save"
        dataCy="global-styles-dialog-modal"
        declineText="Cancel"
        mainText="Global Styles"
        disabled={!isValid}
        {...props}
        onAccept={handleSubmit(onSubmit)}
        loading={loading}
        onDecline={() => {
          handleDeclineSaveGlobalStyle();
        }}
        onOpenChange={handleDeclineSaveGlobalStyle}
        open={!waitToOpen}
        acceptButtonTooltipText={
          needSave
            ? 'Please save your Course before updating the Global Styles.'
            : null
        }
      >
        {loadingFonts ? (
          <LoadingContainer data-cy="loading-fonts-container">
            <Loading dataCy="loading-fonts" />
          </LoadingContainer>
        ) : (
          <Container>
            <>
              <InputContentCustom
                dataCy="page-width-input"
                errorMessage={
                  errors.pageWidth?.message ?? errors.pageWidthUnit?.message
                }
                filled={
                  Boolean(values?.pageWidth) || Boolean(values?.pageWidthUnit)
                }
                inputRef="page-width-input"
                label="Page Width"
              >
                <SmallInputTextCustom
                  dataCy="page-width-input"
                  id="page-width-input"
                  name="pageWidth"
                  max={maxValuePageWidth}
                  min={minValuePageWidth}
                  onKeyDown={(event) => {
                    if (!NumberKeyRegex.test(event.key)) event.preventDefault();
                  }}
                  onChange={handlePageWidthChange}
                  required
                  type="number"
                />
                <SmallSelectDataCustom
                  data={fontUnitsData}
                  dataCy="select-page-width-unit"
                  defaultValue={
                    values.pageWidthUnit ?? fontUnitsData.at(0)?.key
                  }
                  name="select-page-width-unit"
                  onValueChange={handlePageWidthUnitChange}
                  onOpenChange={(isOpen) => {
                    handleOpenChange(isOpen, 'width');
                  }}
                  zIndex={9999}
                />
              </InputContentCustom>
              <InputContentCustom
                dataCy="select-headline-font"
                errorMessage={errors.headlineFont?.message}
                filled={Boolean(values.headlineFont)}
                inputRef="select-headline-font"
                label="Headline Font"
              >
                <SelectDataCustom
                  data={fontsData}
                  dataCy="select-headline-font"
                  defaultValue={values.headlineFont ?? fonts.at(0)?.family}
                  name="select-headline-font"
                  onValueChange={handleHeadlineFontSelectChange}
                  onOpenChange={(isOpen) => {
                    handleOpenChange(isOpen, 'headlineFont');
                  }}
                  zIndex={9999}
                />
              </InputContentCustom>
              <InputContentCustom
                dataCy="headline-font-size"
                errorMessage={
                  errors.headlineFontSize?.message ??
                  errors.headlineFontSizeUnit?.message
                }
                filled={
                  Boolean(values.headlineFontSize) ||
                  Boolean(values.headlineFontSizeUnit)
                }
                inputRef="headline-font-size"
                label="Headline Font Size"
              >
                <SmallInputTextCustom
                  dataCy="headline-font-size"
                  id="headline-font-size"
                  max={maxValueFontSize}
                  min={minValueFontSize}
                  name="headlineFontSize"
                  onKeyDown={(event) => {
                    if (!NumberKeyRegex.test(event.key)) event.preventDefault();
                  }}
                  onChange={handleHeadlineFontSizeChange}
                  required
                  type="number"
                />
                <SmallSelectDataCustom
                  data={fontUnitsData}
                  dataCy="select-headline-font-unit"
                  defaultValue={
                    values.headlineFontSizeUnit ?? fontUnitsData.at(0)?.key
                  }
                  name="select-headline-font-unit"
                  onValueChange={handleHeadlineFontUnitChange}
                  onOpenChange={(isOpen) => {
                    handleOpenChange(isOpen, 'headlineFontUnit');
                  }}
                  zIndex={9999}
                />
              </InputContentCustom>
              <ColorPickerInput
                color={values.headlineFontColor ?? ''}
                dataCy="headline-font-color"
                errorMessage={errors.headlineFontColor?.message}
                label="Headline color"
                name="headlineFontColor"
                onChange={handleHeadlineFontColorChange}
              />
              <InputContentCustom
                dataCy="select-paragraph-font"
                errorMessage={errors.paragraphFont?.message}
                filled={Boolean(values.paragraphFont)}
                inputRef="select-paragraph-font"
                label="Text Font"
              >
                <SelectDataCustom
                  data={fontsData}
                  dataCy="select-paragraph-font"
                  defaultValue={values.paragraphFont ?? fonts.at(0)?.family}
                  name="select-paragraph-font"
                  onValueChange={handleParagraphFontSelectChange}
                  onOpenChange={(isOpen) => {
                    handleOpenChange(isOpen, 'paragraphFont');
                  }}
                  zIndex={9999}
                />
              </InputContentCustom>
              <InputContentCustom
                dataCy="paragraph-font-size"
                errorMessage={
                  errors.paragraphFontSize?.message ??
                  errors.paragraphFontSizeUnit?.message
                }
                filled={
                  Boolean(values.paragraphFontSize) ||
                  Boolean(values.paragraphFontSizeUnit)
                }
                inputRef="paragraph-font-size"
                label="Text Font Size"
              >
                <SmallInputTextCustom
                  dataCy="paragraph-font-size"
                  id="paragraph-font-size"
                  name="paragraphFontSize"
                  max={maxValueFontSize}
                  min={minValueFontSize}
                  onKeyDown={(event) => {
                    if (!NumberKeyRegex.test(event.key)) event.preventDefault();
                  }}
                  onChange={handleParagraphFontSizeChange}
                  required
                  type="number"
                />
                <SmallSelectDataCustom
                  data={fontUnitsData}
                  dataCy="select-paragraph-font-unit"
                  defaultValue={
                    values.paragraphFontSizeUnit ?? fontUnitsData.at(0)?.key
                  }
                  name="select-paragraph-font-unit"
                  onValueChange={handleParagraphFontUnitChange}
                  onOpenChange={(isOpen) => {
                    handleOpenChange(isOpen, 'paragraphFontUnit');
                  }}
                  zIndex={9999}
                />
              </InputContentCustom>
              <ColorPickerInput
                color={values.paragraphFontColor ?? ''}
                dataCy="paragraph-color"
                errorMessage={errors.paragraphFontColor?.message}
                label="Text color"
                name="paragraphFontColor"
                onChange={handleParagraphFontColorChange}
              />
              <ColorPickerInput
                color={values.buttonColor ?? ''}
                dataCy="background-button-color"
                errorMessage={errors.buttonColor?.message}
                label="Background button color"
                name="buttonColor"
                onChange={handleButtonBackgroundColorChange}
              />
              <ColorPickerInput
                color={values.buttonFontColor ?? ''}
                dataCy="button-color"
                errorMessage={errors.buttonFontColor?.message}
                label="Button font color"
                name="buttonFontColor"
                onChange={handleButtonFontColorChange}
              />
              <ColorPickerInput
                color={values.primaryColor ?? ''}
                dataCy="primary-color"
                errorMessage={errors.primaryColor?.message}
                label="Primary color"
                name="primaryColor"
                onChange={handlePrimaryColorChange}
              />
              <ColorPickerInput
                color={values.secondaryColor ?? ''}
                dataCy="secondary-color"
                errorMessage={errors.secondaryColor?.message}
                label="Secondary color"
                name="secondaryColor"
                onChange={handleSecondaryColorChange}
              />
              <ColorPickerInput
                color={hueRotateBaseColor}
                dataCy="hue-rotate"
                errorMessage={errors.hueRotate?.message}
                label={'Template Images Tint'}
                name="hueRotateBaseColor"
                onChange={handleHueRotateBaseColorChange}
                customPreview={
                  <ColorPreview
                    style={{
                      backgroundColor: hueRotateBaseColor,
                      filter: `hue-rotate(${values.hueRotate ?? 0}deg)`,
                    }}
                    title="Preview Base Color with Hue Rotate"
                  />
                }
                customInput={(_handleColorChange) => (
                  <>
                    <SmallInputTextCustom
                      dataCy="hue-input"
                      id="hue-rotate"
                      name="hueRotate"
                      max={maxHueRotate}
                      min={minHueRotate}
                      onKeyDown={(event) => {
                        if (!NumberKeyRegex.test(event.key))
                          event.preventDefault();
                      }}
                      onChange={handleHueRotateChange}
                      required
                      type="number"
                    />
                    <SliderCustom
                      max={maxHueRotate}
                      min={minHueRotate}
                      onValueChange={handleHueRotateSliderChange}
                      value={hueRotateSlider}
                    />
                  </>
                )}
              />
              <TemplateImagesColorsSection hasColors={hasAnyTemplateColor}>
                {hasAnyTemplateColor && (
                  <>
                    {values?.additionalColors.at(0) &&
                      Object.keys(values?.additionalColors).length > 0 && (
                        <BaseCollapsibleMenu
                          baseItemText="Additional Colors"
                          scrollToBottomFunction={() => {
                            additionalColorsBottomRef?.current?.scrollIntoView({
                              behavior: 'smooth',
                            });
                          }}
                        >
                          {fields.map(({ key }, idx) => {
                            return (
                              <ColorPickerContainer key={key}>
                                <ColorPickerSelectData
                                  color={
                                    values?.additionalColors?.[idx]?.color ?? ''
                                  }
                                  dataCy={`template-color-${key}`}
                                  errorMessage={
                                    errors?.additionalColors?.at?.(idx)?.color
                                      ?.message
                                  }
                                  name={`additionalColors.${idx}.color`}
                                  label="Additional Color"
                                  onChange={(color, _colorKey) => {
                                    if (color) {
                                      handleTemplateColorsChange(
                                        color,
                                        `additionalColors.${idx}.color`
                                      );
                                    }
                                  }}
                                />
                              </ColorPickerContainer>
                            );
                          })}
                        </BaseCollapsibleMenu>
                      )}
                  </>
                )}
                <div ref={additionalColorsBottomRef}></div>
                {!hasAnyTemplateColor && (
                  <>
                    <ColorPickerInput
                      dataCy="no-template-primary-color"
                      disabled
                      name="noTemplatePrimaryColor"
                      color={globalStyleDefault.templateColors.primaryColor}
                      required={false}
                    />
                    <ColorPickerInput
                      dataCy="no-template-secondary-color"
                      disabled
                      name="noTemplateSecondaryColor"
                      color={globalStyleDefault.templateColors.secondaryColor}
                      required={false}
                    />
                    <ColorPickerInput
                      dataCy="no-template-background-color"
                      disabled
                      name="noTemplateBackgroundColor"
                      color={globalStyleDefault.templateColors.backgroundColor}
                      required={false}
                    />
                    <ColorPickerInput
                      dataCy="no-template-accent-color"
                      disabled
                      name="noTemplateAccentColor"
                      color={globalStyleDefault.templateColors.accentColor}
                      required={false}
                    />
                    <OverlayDiv data-cy="no-template-colors-overlay">
                      <OverlayText data-cy="no-template-colors-overlay-text">
                        You&apos;d need an SVG to get the templates color
                      </OverlayText>
                    </OverlayDiv>
                  </>
                )}
              </TemplateImagesColorsSection>
            </>
          </Container>
        )}
      </FormModalCustom>
    </FormBase.Provider>
  );
};
